import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductRoutingService {
  private _product$ = new BehaviorSubject<string>(undefined);
  readonly product$ = this._product$.asObservable();
  get product() { return this._product$.getValue(); }
  set product(value) {
    this._product$.next(value);
  }

  private _tenant_id$ = new BehaviorSubject<number>(undefined);
  readonly tenant_id$ = this._tenant_id$.asObservable();
  get tenant_id() { return this._tenant_id$.getValue(); }
  set tenant_id(value) {
    this._tenant_id$.next(value);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.listenToRoutingChanges();
  }

  listenToRoutingChanges() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.product = this.getProduct();
      this.tenant_id = this.getTenantId();
    });
  }

  isPdc() {
    return this.product === 'pdc';
  }

  private getProduct() {
    return this.route.snapshot.firstChild.url[0].toString();
  }

  private getTenantId() {
    const tenant_id = this.route.snapshot.firstChild.url[1];
    if (tenant_id) {
      return Number(tenant_id.toString());
    }
    return undefined;
  }
}
